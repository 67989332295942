import React from 'react'
import PyryImg from '../images/pyry3.jpg'

const Resume = (props) => {
  return (
    <>
      <div className="resume-bg">
        <div className="sectionBg-container">
          <div className="designGuides-container">
            <div className="designGuides_guide"></div>
            <div className="designGuides_guide"></div>
            <div className="designGuides_guide"></div>
            <div className="designGuides_guide"></div>
            <div className="designGuides_guide"></div>
          </div>
        </div>
        <div className="container">
          <div className="resume-layout">
            <div className="resume-header">
              <div
                className="resume-header__image"
                style={
                  {
                    /*height: '250px'*/
                  }
                }
              >
                <img
                  src={PyryImg}
                  style={{
                    width: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                    height: '100%',
                  }}
                />
              </div>
              <div
                className="resume-header__summary"
                style={{
                  display: 'grid',
                  gridRowGap: '1rem',
                  gridAutoRows: 'min-content',
                }}
              >
                <h1
                  style={{
                    margin: '0',
                  }}
                >
                  Summary
                </h1>
                <p
                  style={{
                    margin: '0',
                  }}
                >
                  Fintech freak with great passion towards real-estate investing
                  and financial management softwares. Passionate towards
                  management accounting and strategic leadership. I will bring
                  joy and good spirit to the team I’m around with.
                </p>
              </div>
            </div>
            <div
              className="resume-main-content"
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
              }}
            >
              <div
                style={{
                  height: '300px',
                  width: '348px',
                  margin: '0 auto',
                  width: '100%',
                  padding: '0 15px',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    background: '#e5eef2',
                  }}
                ></div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Resume
